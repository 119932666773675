import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import constants from 'src/utils/constants'

const LoginToken = () => {
  const { token } = useParams()

  useEffect(() => {
    // Store the token in local storage
    localStorage.setItem('token', token)
    const fetchData = async () => {
      try {
        const response = await axios.get(constants.baseUrl + 'admin/user/user-details', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        // alert(response.data.status)
        if (response.data.status) {
          const { data } = response
          const { permission, avatarId } = data

          // Store permission and avatarId in local storage
          localStorage.setItem('permission_id', data.data.permission_id)
          localStorage.setItem('avatar_id', data.data.avatar)
          localStorage.setItem('user_id', data.data.id)

          window.location.hash = '/dashboard'
        } else {
          alert(response.data.message)
          localStorage.removeItem('error_message')
          window.location.hash = '/login'
          // alert(response.data.message)
          localStorage.removeItem('token')
          console.error('Failed to fetch user info:', response.data)
        }
      } catch (error) {
        console.error('An error occurred while fetching user info:', error)
      }
    }

    // Call the fetch function
    fetchData()
    // Redirect to /dashboard
  }, [token])

  return null // Since this component doesn't render anything visible
}

export default LoginToken
