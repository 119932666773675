const env = process.env.REACT_APP_ENV // 'production' or 'localhost'

// const urls = {
//   production: {
//     baseUrl: 'https://api.stage.arshikhan.in/api/',
//     loginWithGoogleUrl: 'https://api.stage.arshikhan.in/login/google',
//     baseWebUrl: 'https://api.stage.arshikhan.in/',
//   },
//   localhost: {
//     baseUrl: 'http://localhost:8000/api/',
//     loginWithGoogleUrl: 'http://localhost:8000/login/google',
//     baseWebUrl: 'http://localhost:8000/',
//   },
// }

const constants = {
  // Permissions
  permissions: {
    superAdmin: '2',
    counselor: '5',
    user: '4',
    manager: '3',
    NoPermission: '1',
    production: '6',
    humanResource: '7',
    proVerifyUser: '8',
    developer: '9',
  },

  pusher: {
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  },

  pusher_channels: {
    counselors: 'counselors',
    counselor: 'counselor-user-',
  },

  notify: {
    counselorCommentedOnYourStudent: {
      title: 'Comment',
      message: ' commented on your student',
      event: 'App\\Events\\UserCommentedOnYourStudent',
      sound: 'https://sk-eng-2023.s3.ap-south-1.amazonaws.com/sound/notification.mp3',
    },

    counselorSharedAFolderWithYou: {
      title: 'Shared A Folder',
      message: ' Shared A Folder With You',
      event: 'App\\Events\\UserSharedAFolderWithYou',
      sound: 'https://sk-eng-2023.s3.ap-south-1.amazonaws.com/sound/notification.mp3',
    },
    counselorRemainderNotificationEvent: {
      title: 'Remainder',
      message: ' has set a reminder for you',
      event: 'App\\Events\\UserRemainderNotificationEvent',
      sound: 'https://sk-eng-2023.s3.ap-south-1.amazonaws.com/sound/reminder.wav',
    },
    counselorApplicationFormFilledEvent: {
      title: 'Application Form Filled',
      message: ' has set a reminder for you',
      event: 'App\\Events\\StudentApplicationFormNotification',
      sound: 'https://sk-eng-2023.s3.ap-south-1.amazonaws.com/sound/application.wav',
    },
    counselorCollegeFeesSubmittedEvent: {
      title: 'College Fees Submitted',
      message: ' has set a reminder for you',
      event: 'App\\Events\\StudentFeesPaymentNotification',
      sound: 'https://sk-eng-2023.s3.ap-south-1.amazonaws.com/sound/admission.wav',
    },
    newClientAdditionEvent: {
      title: 'New Client',
      message: ' New Client Added',
      event: 'App\\Events\\NewClientAddedNotification',
      sound: 'https://sk-eng-2023.s3.ap-south-1.amazonaws.com/sound/admission.wav',
    },
    existingClientDeletedEvent: {
      title: 'Existing Client',
      message: ' Existing Client Deleted',
      event: 'App\\Events\\ExistingClientDeletedNotification',
      sound: 'https://sk-eng-2023.s3.ap-south-1.amazonaws.com/sound/admission.wav',
    },
  },

  docType: {
    DOCUMENT_TYPE_APPLICATION_FORM_FILLED: '2',
    DOCUMENT_TYPE_ADMISSION_FEES_SUBMITTED: '3',
  },

  baseUrl: process.env.REACT_APP_BASE_API_URL,
  logo: 'https://sk-crm-storage-public.s3.ap-south-1.amazonaws.com/images/studentkhabri-red.png',
  loginWithGoogleUrl: process.env.REACT_APP_GOOGLE_LOGIN_URL,
  baseWebUrl: process.env.REACT_APP_BASE_WEB_URL,

  styles: {
    circleStyle: {
      backgroundColor: 'red',
      color: 'white',
      padding: '3px',
      borderRadius: '50%', // Make it fully rounded
      width: '20px', // Adjusted for visibility
      height: '20px', // Adjusted for visibility
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: '13px', // Same as width and height for vertical alignment
    },
  },
}

export default constants
